import React from "react"
import PageLayout from "../../components/PageLayout"
import ReCAPTCHA from "react-google-recaptcha"

const Contact = () => (
  <PageLayout page="Contact Us">
    <p className="mb-6">
      If you wish to get in touch with us, please email{" "}
      <a
        className="text-slate-500 hover:text-slate-700"
        href="mailto:info@aidforchad.org"
      >
        info@aidforchad.org
      </a>{" "}
      or fill in the form below. We will try to get back to you as soon as
      possible.
    </p>
    <form
      name="contact"
      method="POST"
      data-netlify="true"
      netlify-honeypot="hnjkanthlaet"
      data-netlify-recaptcha="true"
    >
      <input type="hidden" name="form-name" value="contact" />
      <label className="hidden">
        <input name="hnjkanthlaet" />
      </label>
      <label className="block mb-2">
        Your Name
        <input
          type="text"
          className="block border border-gray-500 hover:border-black w-full mb-6 rounded p-2"
          name="Name"
          required
        ></input>
      </label>
      <label className="block mb-2">
        Your Email
        <input
          type="email"
          name="Email"
          className="block border border-gray-500 hover:border-black focus:border-black w-full mb-6 rounded p-2"
          required
        ></input>
      </label>
      <label className="block mb-2">
        Your Message
        <textarea
          name="your-message"
          cols="40"
          rows="10"
          className="border border-gray-500 hover:border-black block w-full mb-6 rounded p-2"
          aria-invalid="false"
          required
        ></textarea>
      </label>
      <ReCAPTCHA sitekey="6LdBt5chAAAAACqfsttrXfDQlXDgl6GgHpKKVCBF" />
      <button
        className="mt-6 w-full px-3 py-6 bg-green-500 text-white mb-6 rounded hover:bg-green-600"
        type="submit"
      >
        Send us a message
      </button>
    </form>
  </PageLayout>
)

export default Contact
